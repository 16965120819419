<template>
  <div>
    <v-snackbar v-model="snackbar">
      This website uses cookies to enable Google Analytics. By continuing to use
      this website you agree to Google storing cookies on your computer.
      <v-btn @click="hideDisclaimer" text> more info </v-btn>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="showDisclaimer" z-index="14">
      <v-card>
        <v-card-title> Use of cookies to enable Google Analytics </v-card-title>
        <v-card-text>
          This website uses Google Analytics, a web analytics service provided
          by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are
          text files placed on your computer, to help the website analyze how
          users use the site. The information generated by the cookie about your
          use of the website (including your IP address) will be transmitted to
          and stored by Google on servers in the United States. In case of
          activation of the IP anonymization, Google will truncate/anonymize the
          last octet of the IP address for Member States of the European Union
          as well as for other parties to the Agreement on the European Economic
          Area. Only in exceptional cases, the full IP address is sent to and
          shortened by Google servers in the USA. On behalf of the website
          provider Google will use this information for the purpose of
          evaluating your use of the website, compiling reports on website
          activity for website operators and providing other services relating
          to website activity and internet usage to the website provider. Google
          will not associate your IP address with any other data held by Google.
          You may refuse the use of cookies by selecting the appropriate
          settings on your browser. However, please note that if you do this,
          you may not be able to use the full functionality of this website.
          Furthermore you can prevent Google’s collection and use of data
          (cookies and IP address) by downloading and installing the browser
          plug-in available under
          https://tools.google.com/dlpage/gaoptout?hl=en-GB.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="hideDisclaimer"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "disclaimer",
  data() {
    return {
      disclaimer: false,
      snackbar: true,
    };
  },
  computed: {
    showDisclaimer() {
      return this.$store.getters.showDisclaimer;
    },
  },
  methods: {
    hideDisclaimer() {
      this.$store.commit("showHideDisclaimer");
    },
  },
};
</script>
