<template>
  <v-app>
    <nav-bar v-if="notStartPage" />
    <v-main class="fill-height">
      <router-view />
      <disclaimer />
    </v-main>
  </v-app>
</template>

<script>
import Disclaimer from "./components/disclaimer.vue";
import NavBar from "./components/NavBar.vue";
export default {
  components: { NavBar, Disclaimer },
  name: "App",
  data: () => ({
    //
  }),
  metaInfo: {
    title: "Design",
    // all titles will be injected into this template
    titleTemplate: "%s | Jakob R. Müller",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Presentation of Jakob R. Müller and some of his design projects",
      },
      {
        name: "keywords",
        content:
          "Jakob, Ramon, Müller, Muller, Mueller, design, engineer, engineering, product development, van, van conversion, sewing, carpentry, drawing, sketching, wood, fabric, bags, backpack, travel, blog, outdoor, climbing, adventure, academic, research, researcher, publications, PhD, cv, vita, web design",
      },
      { name: "author", content: "Jakob R. Müller" },
    ],
  },
  computed: {
    notStartPage() {
      console.log(this.$route.path);
      // used to not show navigation when on home (and also contact) page
      if (this.$route.path == "/" || this.$route.path == "/contact") {
        return false;
      } else {
        return true;
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("loadData");
  },
};
</script>

<style>
* {
  overflow-wrap: normal !important;
  word-break: normal !important;
}
</style>
