import Vue from "vue";
import Vuex from "vuex";

function random_s4() {
  // returns a random 4 digit alphanumeric
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

Vue.use(Vuex);

const dataStore = {
  state: {
    // START IMAGES -----------------------------
    jakobImages: [
      {
        file: "https://files.jakob.design/img/jakob/GOPR2111_1620940001401.jpg",
        description: "Rock climbing Patos de la Moncloe 7b+, in Forada, Spain",
        image: "slug",
      },
      {
        file: "https://files.jakob.design/img/jakob/DSC06336.jpg",
        description: "In front of Alicante, Spain. Photo by Elena Malz",
      },
      {
        file: "https://files.jakob.design/img/jakob/DSC04713.jpg",
        description: "In Åre, Sweden. Photo by Elena Malz",
      },
      {
        file: "https://files.jakob.design/img/jakob/20210224_114603.jpg",
        description: "In Åre, Sweden. Photo by Elena Malz",
      },
    ],
    designImages: [
      // {
      //   file: "https://files.jakob.design/img/design/20200905_153512.jpg",
      //   description: "Cardboard model and sketches for a van conversion",
      // },
      // {
      //   file: "https://files.jakob.design/img/design/DSC05329.jpg",
      //   description:
      //     "Photograph of an acrobat in the Ciudad des Artes y Sciences, Valencia, Spain",
      // },
      // {
      //   file: "https://files.jakob.design/img/design/20200606_234943.jpg",
      //   description: "Fabric case for camera, render and final product",
      // },
      // {
      //   file: "https://files.jakob.design/img/design/20191108_223432.jpg",
      //   description: "Render of a sailboat in pencil, marker and digital",
      // },
      // {
      //   file: "https://files.jakob.design/img/design/20200525_231452.jpg",
      //   description: "Fabric cases for camera and tele-lens",
      // },
    ],
    // MENUS -------------------------------------------------------------
    designMenu: [
      {
        title: "van build",
        link: { name: "van" },
        image: "van_sunset",
      },
      {
        title: "fabric",
        link: { name: "fabric" },
        image: "2camerabags",
      },
      {
        title: "sketches",
        link: { name: "sketches" },
        image: "sketch_boat",
      },
      {
        title: "web",
        link: { name: "web" },
        image: "coffee",
      },
    ],
    jakobMenu: [
      {
        title: "vita",
        link: { name: "vita" },
        image: "jakob_snowcoffee",
      },
      {
        title: "academic",
        link: { name: "academic" },
        image: "jakob_academic",
      },
      {
        title: "travel",
        link: { name: "travel" },
        image: "jakob_travel",
      },
      {
        title: "contact",
        link: { name: "contact" },
        image: "jakob_contact",
      },
    ],
    emptyCoffeePic: {
      url: "https://files.jakob.design/img/20210624_091553.jpg",
      file: "https://files.jakob.design/img/20210624_091553.jpg",
      description:
        "This element has sadly not yet been implemented. But we are working on it as soon as we have coffee again...",
    },
    pictures: [],
  },
  getters: {
    jakobImageRandom: (state) => {
      // returns a random picture url from jakobImages
      let index = Math.floor(Math.random() * Number(state.jakobImages.length));
      console.log(index);
      return state.jakobImages[index];
    },
    designImageRandom: (state) => {
      // returns a random picture url from jakobImages
      let index = Math.floor(Math.random() * Number(state.designImages.length));
      console.log(index);
      console.log(index);
      return state.designImages[index];
    },
    designMenu: (state) => {
      return state.designMenu;
    },
    jakobMenu: (state) => {
      return state.jakobMenu;
    },
    notYetImplemented: (state) => {
      return state.emptyCoffeePic;
    },
    designImages: (state) => {
      return state.jakobImages;
    },
    // pictures from DB
    pictureBySlug: (state) => (slug) => {
      let img = state.pictures.find((p) => p.slug == slug);
      // if (img) {
      return img;
      // } else {
      //   return state.emptyCoffeePic;
      // }
    },
    pictureByKey: (state) => (key) => {
      return state.pictures.find((p) => p.key == key);
    },
    picturesByCategory: (state) => (cat) => {
      return state.pictures.filter((p) => p.category == cat);
    },
  },
  mutations: {
    setAllPictures(state, payload) {
      console.log(payload);
      state.pictures = payload;
    },
  },
  actions: {
    async loadData({ dispatch }) {
      await dispatch("getPictures");
    },
    async getPictures({ commit, dispatch }) {
      console.log("about to load  all pictures");
      let allPictures = await dispatch(
        "apiCall",
        {
          url: "images/",
          method: "GET",
        },
        { root: true }
      );
      // in case error, so it's not "undefined" but empty array
      if (!allPictures) {
        allPictures = [];
      }
      commit("setAllPictures", allPictures);
      console.log("loaded all pictures");
    },
  },
  modules: {},
};

export default new Vuex.Store({
  state: {
    loginSessionDuration: 30 * 60 * 1000, // time until logout
    token: null,
    backendURL: "https://jakobdesigndeta-1-p2611277.deta.app/",
    // backendURL: "https://4ohx4r.deta.dev/",  //old deta server
    //backendURL: 'http://127.0.0.1:8000/',
    messages: [],
    // {
    //   type: warning, error or info (info standard)
    //   message: String,
    //   component: string - component name, default 'general'
    //   id: 4-digit-random-alphanumerical
    // }
    loading: false, // bool for login loading process, should be reworked
    APIloaded: false, // indicates whether the backend data has been loaded
    apps: [], // which apps are used in this frontend ; filled from backend based on settings
    dataDisclaimer: false,
  },
  getters: {
    getUser: (state) => {
      // returns the user object as set from backend
      return state.user;
    },
    getUserScope: (state) => {
      if (state.user) {
        return state.user.scopes;
      } else {
        return null;
      }
    },
    getAuthToken: (state) => {
      if (state.token) {
        return state.token;
      } else {
        return {
          token: sessionStorage.getItem("access_token"),
          token_type: sessionStorage.getItem("token_type"),
        };
      }
    },
    loggedIn: (state, getters) => {
      // returns true or false
      let deltaT = state.loginSessionDuration;
      let token = getters.getAuthToken;

      if (
        // check if we have sessionStorage auth
        token.access_token &&
        token.token_type
        // check if the 30min session is over:
        // (sessionStorage.getItem("loginTime") +deltaT) > Date.now()
      ) {
        console.log("found session storage auth");
        return true;
      } else {
        console.log("login check failed");
        console.log(sessionStorage.getItem("token_type"));
        console.log(sessionStorage.getItem("access_token"));
        console.log(sessionStorage.getItem("loginTime") > Date.now() - deltaT);
        return false;
      }
    },
    getAllMessages: (state) => {
      // returns an array of all messages
      return state.messages;
    },
    getErrorsOfComponent: (state) => (componentName) => {
      // returns an array of all errors
      // used to display error messages in App.vue
      return state.messages.filter((e) => e.component == componentName);
    },
    isLoading: (state) => {
      return state.loading;
    },
    allApps: (state) => {
      return state.apps;
    },
    backendURL: (state) => {
      return state.backendURL;
    },
    showDisclaimer: (state) => {
      return state.dataDisclaimer;
    },
  },
  mutations: {
    logout(state) {
      // remove user-related objects from store:
      state.apps = [];
      state.user = null;
      sessionStorage.removeItem("token_type");
      sessionStorage.removeItem("access_token");
      this.commit("setAuthToken", null);
      this.commit("goodNews", "you have been logged out!");
      console.log("logged out...");
    },
    setAuthToken(state, token) {
      state.token = token;
      if (token) {
        // sessionStorage.setItem("access_token", token["access_token"]);
        // sessionStorage.setItem("token_type", token["token_type"]);
        sessionStorage.setItem("loginTime", Date.now());
      }
    },
    registerError(state, payload) {
      // registers a new error
      // payload either string as error message or object, see states

      // first generate an ID
      let errorID = random_s4();
      while (state.messages.filter((e) => e.id == errorID).length) {
        // generate new one
        errorID = random_s4();
      }

      var error = {
        type: "error",
        id: errorID,
      };
      if (typeof payload === String) {
        error.message = payload;
        error.component = "general";
      } else {
        error.message = payload.message;
        error.component = payload.component;
      }
      console.log("error by " + error.component + ": " + error.message);
      state.messages.push(error);
    },
    removeMessage(state, errorID) {
      // removes errors by array index; used in ErrorMessage.vue to dismiss not only the error but remove it
      state.messages = state.messages.filter((e) => e.id != errorID);
    },
    clearMessages(state, componentName) {
      // use with caution when component = ''
      // otherwise to clear all errors of a component, e.g. 'LoginProcess'
      if (componentName) {
        console.log("deleting errors of " + componentName);
        state.messages = state.messages.filter(
          (e) => e.component != componentName
        );
      } else {
        console.log("deleting ALL messages");
        state.messages = [];
      }
    },
    goodNews(state, payload) {
      console.log("good news: " + payload);
      // first create ID
      let newsID = random_s4();
      while (state.messages.filter((e) => e.id == newsID).length) {
        // generate new one
        newsID = random_s4();
      }
      const news = {
        type: "info",
        component: "",
        message: payload,
        id: newsID,
      };
      state.messages.push(news);
    },
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    // USER MGMT
    setUserMe(state, payload) {
      state.user = payload;
    },
    // APPS
    addAPP(state, payload) {
      state.apps.push(payload);
    },
    removeAllApps(state) {
      state.apps = [];
    },
    showHideDisclaimer(state) {
      state.dataDisclaimer = !state.dataDisclaimer;
    },
  },
  actions: {
    async login({ getters, commit }, user) {
      commit("startLoading"); // loading window
      // the login process
      commit("clearMessages", "loginProcess");

      const backend = getters.backendURL;
      // create form body for oauth2 login scheme
      var formBody = [];
      for (var property in user) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(user[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      try {
        // commit to backend:
        const response = await fetch(backend + "core/auth/token/", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: formBody,
        });

        const status = response.status;
        const data = await response.json();

        // in case of success:
        if (status === 200) {
          commit("goodNews", "Log in sucessful!");
          // success!
          let token = {
            access_token: data.access_token,
            token_type: data.token_type,
          };
          commit("setAuthToken", token);
          console.log("set auth credentials to storage");

          commit("stopLoading");
          return true;
        } else if (status === 400) {
          commit("auth_error");
          commit("registerError", {
            message: "Could not log in; wrong username or password",
            component: "loginProcess",
          });
          // throw "Could not log in; wrong username or password";
        } else {
          console.error(status, data);
          commit("auth_error");
          commit("registerError", {
            message: "error " + status + " please try again later",
            component: "loginProcess",
          });
          // throw "error " + status + " please try again later";
        }
      } catch (error) {
        console.error(error);
        commit("auth_error");
        commit("registerError", {
          message: "server error while logging in; please try again later",
          component: "loginProcess",
        });
        // throw "server error; please try again later";
      }
      commit("stopLoading");
    },
    async apiCall(
      { commit, getters },
      { url, query = [], method = "GET", objectData = null }
    ) {
      // generalised apiCall to the backend, can be used for GET/POST/PUT/DELETE
      commit("startLoading"); // loading icon

      console.log(url);
      console.log(method);
      console.log(objectData);

      let returnValue = false;

      const backend = getters.backendURL;

      try {
        ////////////////////////////////////////////////////////////
        // assembling URL
        let urlToFetch = String(backend + url);
        if (query.length) {
          // console.log(query)
          urlToFetch = urlToFetch + "?";
          for (let q of query) {
            // console.log(q)
            urlToFetch = urlToFetch + q.query + "=" + q.value + "&";
          }
        }
        ////////////////////////////////////////////////////////////
        // assembling message data:
        let messageData = {};
        let messageHeader = {};
        if (getters.loggedIn) {
          const token = getters.getAuthToken;
          // in case of logged in we want to add authorisation
          // doesn't hurt if it is not requried...
          console.log("logged in, adding auth");
          messageHeader = Object.assign(messageHeader, {
            Authorization: token.token_type + " " + token.access_token,
          });
        }
        if (method != "GET") {
          // If we have another method
          messageData = Object.assign(messageData, {
            method: method,
          });
        }

        if (objectData && method != "GET") {
          // need to add content to header
          messageHeader = Object.assign(messageHeader, {
            "Content-Type": "application/json",
          });
          // need to attach body
          messageData = Object.assign(messageData, {
            body: JSON.stringify(objectData),
          });
        }

        if (messageHeader) {
          // add header if not empty
          messageData = Object.assign(messageData, {
            headers: messageHeader,
          });
        }

        console.log(messageData);
        // console.log(settings.backend)
        console.log(urlToFetch);

        const response = await fetch(urlToFetch, messageData);

        //const data = await response.json()
        if (response.status === 200) {
          //console.log(response.json())
          returnValue = await response.json();
          console.log(returnValue);
          // console.log(returnValue)
          if (!returnValue) {
            // in case of e.g. deletion where there is only 200 as response
            returnValue = true;
          }
        } else {
          returnValue = await response.json();
          console.log(returnValue);
          commit("registerError", {
            message:
              "Error " +
              response.status +
              " from backend: " +
              returnValue.detail,
            component: "APIcall",
          });
        }
        // } else if (response.status === 401) {
        //   // commit("logout");
        //   commit("registerError", {
        //     message:
        //       "You are not logged in or don't have permission for this function",
        //     component: "APIcall",
        //   });
        // } else if (response.status === 404) {
        //   commit("registerError", {
        //     message: "Element couold not be found",
        //     component: "APIcall",
        //   });
        //   returnValue = 404; // identifier for a 404
        // } else {
        //   commit("registerError", {
        //     message:
        //       "Error " +
        //       response.status +
        //       ' Could not perform API call for: url="' +
        //       url +
        //       '", method="' +
        //       method +
        //       '", data="' +
        //       JSON.stringify(objectData) +
        //       '".',
        //     component: "APIcall",
        //   });
        // }
      } catch (error) {
        console.error(error);
        commit("registerError", {
          message: "APIcall produced an error: " + error,
          component: "APIcall",
        });
      }
      commit("stopLoading");
      return returnValue;
    },
  },
  modules: {
    data: dataStore,
  },
});
