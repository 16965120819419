<template>
  <v-row style="height: 100%" class="ma-0 pa-0">
    <v-col
      fill-height
      :cols="jakobCols"
      class="ma-0 pa-0 align-center"
      style="position: relative"
    >
      <transition name="fade">
        <v-img
          :src="jakobImage.url"
          :alt="jakobImage.description"
          :key="jakobImage.url"
          style="height: 100%; max-height: 100%; position: fixed"
          :lazy-src="jakobImage.thumbUrl"
          cover
          position="center center"
          @click="select = null"
        />
      </transition>
      <!-- JAKOB -->
      <v-row
        :align="portrait ? 'end' : 'center'"
        :justify="portrait ? 'center' : 'end'"
        style="height: 100%"
        no-gutters
      >
        <v-menu
          :offset-x="!portrait"
          :left="!portrait"
          :top="portrait"
          :offset-y="portrait"
          eager
        >
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              :style="
                portrait
                  ? 'position:absolute;'
                  : 'position:absolute; right:0px;'
              "
              :class="[
                'my-auto',
                'mx-0',
                'pa-2',
                'white',
                'black--text',
                'text-md-h1',
                'text-h3',
                portrait ? 'rounded-t' : 'rounded-l',
              ]"
              @click="select = 'jakob'"
              v-bind="attrs"
              v-on="on"
            >
              jakob
            </v-sheet>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in jakobMenu"
              :key="index"
              :to="item.link"
              @mouseover="setJakobImage(item.image)"
            >
              <v-list-item-title class="pa-5 text-md-h3 text-h6">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- pic text -->
        <v-sheet
          absolute
          class="homeImageText ma-1 pa-1 white text--black text-caption"
          :style="portrait ? 'top:0px; bottom:auto; right:0px;' : 'left:0px;'"
          rounded
          elevation="3"
        >
          {{ jakobImage.description }}
        </v-sheet>
      </v-row>
    </v-col>

    <!-- DESIGN -->
    <v-col
      fill-height
      :cols="designCols"
      class="ma-0 pa-0"
      style="background-color: white"
    >
      <transition name="fade">
        <!-- IMAGE  -->
        <v-img
          :src="designImage.url"
          :alt="designImage.description"
          :key="designImage.url"
          style="height: 100%; max-height: 100%; position: fixed"
          :lazy-src="designImage.thumbUrl"
          cover
          position="center center"
          @click="select = null"
        />
      </transition>

      <!-- design menu positioning -->
      <v-row
        :align="portrait ? 'start' : 'center'"
        :justify="portrait ? 'center' : 'start'"
        style="height: 100%"
        no-gutters
      >
        <v-menu :offset-x="!portrait" :offset-y="portrait" :bottom="portrait">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              :class="[
                portrait ? '' : 'my-auto',
                'mx-0 pa-2',
                'black',
                'white--text',
                'text-md-h1',
                'text-h3',
                portrait ? 'rounded-b' : 'rounded-r',
              ]"
              @click="select = 'design'"
              v-bind="attrs"
              v-on="on"
              style="z-index: 10"
            >
              design
            </v-sheet>
          </template>

          <v-list class="black white--text">
            <v-list-item
              v-for="(item, index) in designMenu"
              :key="index"
              :to="item.link"
              @mouseover="setDesignImage(item.image)"
            >
              <v-list-item-title
                class="black white--text text-md-h3 text-h6 pa-5"
              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- pic text design pic -->
        <v-sheet
          absolute
          class="homeImageText ma-1 pa-1 white text--black text-caption"
          style="right: 0px"
          rounded
          elevation="3"
        >
          {{ designImage.description }}
        </v-sheet>
      </v-row>
    </v-col>

    <v-dialog v-model="showContact" hide-overlay persistent width="300">
      <v-card class="pa-10">
        <v-car-title> Contact: </v-car-title>
        <v-card-text class="pa-0">
          Jakob R. Müller <br />
          jakobramonmueller@gmail.com <br />
          +46 767 602143

          <v-divider />

          <p class="text-caption">
            Jakob R. Müller is the author, and as such responsible, for all
            contents on this website if not specifically declared differently.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn :to="{ name: 'Home' }"> close </v-btn>
          <v-btn @click="showDisclaimer"> Data privacy </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      select: null, //jakob, design or null
      jakobImageSlug: null,
      designImageSlug: null,
      isPortraitMode: false,
    };
  },
  props: {
    showContact: { default: false },
  },
  computed: {
    ...mapGetters([
      "jakobImageRandom",
      "designImageRandom",
      "jakobMenu",
      "designMenu",
      "pictureBySlug",
    ]),
    jakobCols() {
      // portrait:
      if (this.portrait) {
        return 12;
      }

      // landscape
      if (this.select == "jakob") {
        return 9;
      } else if (this.select == "design") {
        return 3;
      } else {
        return 6;
      }
    },
    designCols() {
      // portrait:
      if (this.portrait) {
        return 12;
      } else {
        return 12 - this.jakobCols;
      }
    },
    jakobImage() {
      return this.pictureBySlug(this.jakobImageSlug);
    },
    designImage() {
      return this.pictureBySlug(this.designImageSlug);
    },
    portrait() {
      return this.isPortraitMode;
    },
  },
  methods: {
    imageSlideshow(jakobIndex = 0, designIndex = 0) {
      // load menu itmes
      const menuItemsJ = this.jakobMenu;
      const menuItemsD = this.designMenu;
      // console.log(menuItems[jakobIndex].image)
      // console.log(jakobIndex)

      // set images
      if (!this.select) {
        // only if there is no direct selection going on
        this.setJakobImage(menuItemsJ[jakobIndex].image);
        this.setDesignImage(menuItemsD[designIndex].image);
      }

      // raise indizes
      let newIndexJ = jakobIndex + 1;
      if (newIndexJ >= menuItemsJ.length) {
        newIndexJ = 0;
      }
      let newIndexD = designIndex + 1;
      if (newIndexD >= menuItemsD.length) {
        newIndexD = 0;
      }

      // recursive call after 5sec
      // setInterval(() => {
      //   this.imageSlideshow(newIndexJ, newIndexD)
      // }, 5000);
    },
    setJakobImage(imgSlug) {
      // console.log(imgSlug)
      this.jakobImageSlug = imgSlug;
    },
    setDesignImage(imgSlug) {
      this.designImageSlug = imgSlug;
    },

    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        console.log("portrait");
        this.isPortraitMode = true;
      } else if (orientation === "landscape-primary") {
        // landscape mode
        console.log("landscape");
        this.isPortraitMode = false;
      }
    },
    // from contacts:
    showDisclaimer() {
      this.$store.commit("showHideDisclaimer");
      this.$router.push({ name: "Home" });
    },
  },
  mounted() {
    this.imageSlideshow();
    this.handleOrientationChange();

    window.addEventListener("orientationchange", this.handleOrientationChange);
  },
};
</script>

<style scoped>
.homeImageText {
  position: absolute;
  bottom: 0px;
  /* opacity: 70%; */
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
