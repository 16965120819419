<template>
  <v-container
    class="pa-0 ma-0"
    style="width: 100vw; height: 100vh; max-width: 100000px"
  >
    <v-img
      :src="notYetImplemented.file"
      style="height: 100%; width: 100%"
      cover
      position="center center"
      class="ma-0"
    >
      <v-sheet
        absolute
        class="ma-5 pa-5 white text--black text-caption"
        style="left: 0px"
        rounded
        elevation="3"
      >
        This page is currently still under construction.
        <v-btn :to="{ name: 'Home' }"> back to home </v-btn>
      </v-sheet>

      <!-- pic text -->
      <v-sheet
        absolute
        class="homeImageText ma-5 pa-5 white text--black text-caption"
        style="left: 0px"
        rounded
        elevation="3"
      >
        {{ notYetImplemented.description }}
      </v-sheet>
    </v-img>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["notYetImplemented"]),
  },
};
</script>

<style scoped>
.homeImageText {
  position: absolute;
  bottom: 0px;
  opacity: 65%;
}
</style>
