import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

// Configuration VueAnalytics
Vue.use(VueGtag, {
  config: { id: "G-E4WBJ63YQC" },
});
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
