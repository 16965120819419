var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"ma-0 pa-0 align-center",staticStyle:{"position":"relative"},attrs:{"fill-height":"","cols":_vm.jakobCols}},[_c('transition',{attrs:{"name":"fade"}},[_c('v-img',{key:_vm.jakobImage.url,staticStyle:{"height":"100%","max-height":"100%","position":"fixed"},attrs:{"src":_vm.jakobImage.url,"alt":_vm.jakobImage.description,"lazy-src":_vm.jakobImage.thumbUrl,"cover":"","position":"center center"},on:{"click":function($event){_vm.select = null}}})],1),_c('v-row',{staticStyle:{"height":"100%"},attrs:{"align":_vm.portrait ? 'end' : 'center',"justify":_vm.portrait ? 'center' : 'end',"no-gutters":""}},[_c('v-menu',{attrs:{"offset-x":!_vm.portrait,"left":!_vm.portrait,"top":_vm.portrait,"offset-y":_vm.portrait,"eager":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-sheet',_vm._g(_vm._b({class:[
              'my-auto',
              'mx-0',
              'pa-2',
              'white',
              'black--text',
              'text-md-h1',
              'text-h3',
              _vm.portrait ? 'rounded-t' : 'rounded-l',
            ],style:(_vm.portrait
                ? 'position:absolute;'
                : 'position:absolute; right:0px;'),on:{"click":function($event){_vm.select = 'jakob'}}},'v-sheet',attrs,false),on),[_vm._v(" jakob ")])]}}])},[_c('v-list',_vm._l((_vm.jakobMenu),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.link},on:{"mouseover":function($event){return _vm.setJakobImage(item.image)}}},[_c('v-list-item-title',{staticClass:"pa-5 text-md-h3 text-h6"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1),_c('v-sheet',{staticClass:"homeImageText ma-1 pa-1 white text--black text-caption",style:(_vm.portrait ? 'top:0px; bottom:auto; right:0px;' : 'left:0px;'),attrs:{"absolute":"","rounded":"","elevation":"3"}},[_vm._v(" "+_vm._s(_vm.jakobImage.description)+" ")])],1)],1),_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"background-color":"white"},attrs:{"fill-height":"","cols":_vm.designCols}},[_c('transition',{attrs:{"name":"fade"}},[_c('v-img',{key:_vm.designImage.url,staticStyle:{"height":"100%","max-height":"100%","position":"fixed"},attrs:{"src":_vm.designImage.url,"alt":_vm.designImage.description,"lazy-src":_vm.designImage.thumbUrl,"cover":"","position":"center center"},on:{"click":function($event){_vm.select = null}}})],1),_c('v-row',{staticStyle:{"height":"100%"},attrs:{"align":_vm.portrait ? 'start' : 'center',"justify":_vm.portrait ? 'center' : 'start',"no-gutters":""}},[_c('v-menu',{attrs:{"offset-x":!_vm.portrait,"offset-y":_vm.portrait,"bottom":_vm.portrait},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-sheet',_vm._g(_vm._b({class:[
              _vm.portrait ? '' : 'my-auto',
              'mx-0 pa-2',
              'black',
              'white--text',
              'text-md-h1',
              'text-h3',
              _vm.portrait ? 'rounded-b' : 'rounded-r',
            ],staticStyle:{"z-index":"10"},on:{"click":function($event){_vm.select = 'design'}}},'v-sheet',attrs,false),on),[_vm._v(" design ")])]}}])},[_c('v-list',{staticClass:"black white--text"},_vm._l((_vm.designMenu),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.link},on:{"mouseover":function($event){return _vm.setDesignImage(item.image)}}},[_c('v-list-item-title',{staticClass:"black white--text text-md-h3 text-h6 pa-5"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1),_c('v-sheet',{staticClass:"homeImageText ma-1 pa-1 white text--black text-caption",staticStyle:{"right":"0px"},attrs:{"absolute":"","rounded":"","elevation":"3"}},[_vm._v(" "+_vm._s(_vm.designImage.description)+" ")])],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.showContact),callback:function ($$v) {_vm.showContact=$$v},expression:"showContact"}},[_c('v-card',{staticClass:"pa-10"},[_c('v-car-title',[_vm._v(" Contact: ")]),_c('v-card-text',{staticClass:"pa-0"},[_vm._v(" Jakob R. Müller "),_c('br'),_vm._v(" jakobramonmueller@gmail.com "),_c('br'),_vm._v(" +46 767 602143 "),_c('v-divider'),_c('p',{staticClass:"text-caption"},[_vm._v(" Jakob R. Müller is the author, and as such responsible, for all contents on this website if not specifically declared differently. ")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"to":{ name: 'Home' }}},[_vm._v(" close ")]),_c('v-btn',{on:{"click":_vm.showDisclaimer}},[_vm._v(" Data privacy ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }