import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NotYetImplemented from "../views/NotYetImplemented.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/vita",
    name: "vita",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vita.vue"),
    // component: NotYetImplemented
  },
  {
    path: "/academic",
    name: "academic",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/academic.vue"),
    // component: NotYetImplemented
  },
  {
    path: "/travel",
    name: "travel",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/travel.vue"),
    // component: NotYetImplemented
  },
  {
    path: "/contact",
    name: "contact",
    props: { showContact: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/NotYetImplemented.vue"),
    component: Home,
  },
  {
    path: "/van",
    name: "van",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vanBuild.vue"),
    // component: NotYetImplemented
  },
  {
    path: "/fabric",
    name: "fabric",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/fabric.vue"),
    // component: NotYetImplemented
  },
  {
    path: "/sketches",
    name: "sketches",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/sketches.vue"),
  },
  {
    path: "/web",
    name: "web",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/NotYetImplemented.vue"),
    component: NotYetImplemented,
  },
  {
    path: "",
    name: "web",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/NotYetImplemented.vue"),
    component: NotYetImplemented,
  },
  {
    path: "/404",
    alias: "*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/notFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
