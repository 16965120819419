<template>
  <v-app-bar>
    <!-- back button  -->
    <v-btn
      :to="{ name: 'Home' }"
      :fab="$vuetify.breakpoint.smAndDown"
      small
      absolute
    >
      <v-icon>mdi-arrow-left</v-icon>
      <span v-if="!$vuetify.breakpoint.smAndDown">back</span>
    </v-btn>

    <v-row>
      <v-col cols="6" class="pa-0 ma-0 d-flex flex-row-reverse">
        <v-menu offset-x left rounded="l-lg r-0">
          <v-list
            :class="$vuetify.breakpoint.mdAndUp ? 'd-flex align-start' : ''"
            nav
            class="ma-0 pa-0 white"
          >
            <!-- Jakob menu  -->
            <v-list-item
              v-for="(m, index) in jakobMenu"
              :key="index"
              class="ma-0"
              height="auto"
              style="min-height: 0px !important; height: 40px !important"
              :to="m.link"
            >
              {{ m.title }}
            </v-list-item>
          </v-list>

          <!-- jakob button -->
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              class="my-auto mx-0 pa-2 white rounded-l text-right"
              width="60px"
              :class="select == 'jakob' ? 'font-weight-bold' : ''"
              @click="changeSelect('jakob')"
              elevation="3"
              v-bind="attrs"
              v-on="on"
            >
              jakob
            </v-sheet>
          </template>
        </v-menu>
      </v-col>

      <!-- DESIGN -->
      <v-col cols="6" class="pa-0 ma-0 d-flex">
        <v-menu offset-x rounded="r-lg l-0">
          <template v-slot:activator="{ on, attrs }">
            <!-- DESIGN BUTTON -->
            <v-sheet
              class="my-auto mx-0 pa-2 black white--text rounded-r"
              :class="select == 'design' ? 'font-weight-bold' : ''"
              width="60px"
              @click="changeSelect('design')"
              elevation="3"
              v-bind="attrs"
              v-on="on"
            >
              design
            </v-sheet>
          </template>

          <!-- DESIGN MENU -->
          <v-list
            :class="$vuetify.breakpoint.mdAndUp ? 'd-flex align-start' : ''"
            nav
            class="ma-0 pa-0 black"
          >
            <v-list-item
              v-for="(m, index) in designMenu"
              :key="index"
              class="ma-0 px-2 white--text"
              height="auto"
              style="
                min-height: 0px !important;
                height: 40px !important;
                width: 80px;
              "
              :to="m.link"
            >
              {{ m.title }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      select: null,
    };
  },
  computed: {
    ...mapGetters(["jakobMenu", "designMenu"]),
  },
  methods: {
    changeSelect(name) {
      if (this.select == name) {
        this.select = null;
      } else {
        this.select = name;
      }
    },
  },
};
</script>
